import { Session } from '@supabase/supabase-js'
import { createContext } from 'react'

import { SupabaseUserMetadata } from '@/models'
import { noop } from '@/utils'

export const AuthContext = createContext<{
  jwt?: string
  userMetadata?: SupabaseUserMetadata
  isInitialLogIn: boolean
  session: Session | null | undefined
  isSigningOut: boolean
  setIsSigningOut(value: boolean): void
  updateToken(
    newToken: string,
    onSuccess?: () => void,
    refreshToken?: string,
  ): void
  getCurrentToken(): string | undefined
  isPublicPage(pathanme: string): boolean
  isProtectedPage(pathanme: string): boolean
  goToInitialPage(): void
}>({
  isInitialLogIn: true,
  session: null,
  updateToken: noop,
  isSigningOut: false,
  setIsSigningOut: noop,
  getCurrentToken: noop,
  isPublicPage: noop,
  isProtectedPage: noop,
  goToInitialPage: noop,
})
